.menu-rotate-open {
  animation: menu-open 250ms linear forwards;
}

.menu-rotate-close {
  animation: menu-close 250ms linear forwards;
}

@keyframes menu-open {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes menu-close {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.deposit-text-animation {
  animation: deposit-blink 1.5s ease 0s infinite normal none running;
}

@keyframes deposit-blink {
  0% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
  25% {
    -webkit-transform: scale(0.83);
    transform: scale(0.83);
  }
  50% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
  75% {
    -webkit-transform: scale(0.83);
    transform: scale(0.83);
  }
  100% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
}

.share-button {
  animation: share-rotate 6s linear infinite;
}

@keyframes share-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
  100% {
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn);
  }
}

.container-no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container-no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.alert-pulse {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.glass-background {
  background: rgba(22, 198, 86, 0.14);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.9px);
  -webkit-backdrop-filter: blur(7.9px);
  border: 1px solid rgba(22, 198, 86, 0.34);
  /* box-shadow: 0px 0px 10px 10px rgba(22, 198, 86, 0.4);
  -webkit-box-shadow: 0px 0px 10px 10px rgba(22, 198, 86, 0.4);
  -moz-box-shadow: 0px 0px 10px 10px rgba(22, 198, 86, 0.4); */
}

@media only screen and (max-width: 400px) {
  .visitor-buttons {
    white-space: nowrap;
    font-size: 3vw !important;
    padding: 8px 5px !important;
  }
}
